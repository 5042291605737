import React from 'react';
import Layout from '../components/Layout';

const HomePage = () => {
  // Data dummy
  const totalLaporan = 20;
  const totalStaff = 20;
  const staffTimA = 3;
  const staffTimB = 2;
  const staffIzin = 10;
  const staffCuti = 5;

  const listStaffTimA = [
    { id: 1, name: 'Ripan' },
    { id: 2, name: 'Janet' },
    { id: 3, name: 'Banet' },
    // Tambahkan data lainnya sesuai kebutuhan
  ];

  const listStaffTimB = [
    { id: 3, name: 'Bobi' },
    { id: 4, name: 'Alice' },
    // Tambahkan data lainnya sesuai kebutuhan
  ];

  return (
    <Layout>
      <div className="p-4">
        <h2 className="text-2xl font-bold dark:text-white mb-4">Beranda</h2>
        <h1 className="text-2xl font-bold dark:text-white mt-4 text-center">Selamat Datang di GeoMap</h1>
       
      </div>
    </Layout>
  );
};

export default HomePage;
