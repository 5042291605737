import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Layout from "../components/Layout";
// import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios untuk melakukan permintaan API

const Registration = () => {
  // State untuk menyimpan data formulir pendaftaran
  const [formData, setFormData] = useState({
    NRKorNIP: "",
    Nama: "",
    Email: "",
    Jenis: "",
    Satuan: "",
    Role: "",
    userID_admin: "",
  });
  const [userRole, setUserRole] = useState("");
  const userID = localStorage.getItem('IDs');
  console.log(userID);

  useEffect(() => {
    const role = localStorage.getItem('Role');
    setUserRole(role);

    // Set userID_admin in formData
    setFormData(prevData => ({
      ...prevData,
      userID_admin: userID
    }));

    // Debugging
    console.log("User ID from localStorage:", userID);
    console.log("Role from localStorage:", role);
  }, [userID]);

  // Fungsi untuk meng-handle perubahan input dalam formulir
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      userID_admin: userID
    }));
  };

  // Fungsi untuk meng-handle pengiriman formulir pendaftaran
  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Yakin ingin membuat user baru?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Kirim permintaan POST ke API
          const response = await axios.post(
            "https://jaktraffic.my.id/geoapi/user/reg",
            formData
          );

          // Handle success
          console.log("API Response:", response.data.message);

          // Tampilkan notifikasi sukses
          Swal.fire({
            title: response.data.message,
            icon: "success",
          });

          // Reset form
          setFormData({
            NRKorNIP: "",
            Nama: "",
            Email: "",
            Jenis: "",
            Satuan: "",
            Role: "",
            userID_admin: userID,
          });
        } catch (error) {
          console.error("API Error:", error);

          // Handle error
          Swal.fire({
            title: "Terjadi kesalahan saat membuat user baru",
            icon: "error",
            text: error.response.data.message,
          });
        }
      }
    });
  };

  const renderRoleOptions = () => {
    switch (userRole) {
      case "Master":
        return (
          <>
            <option value="">Pilih Role</option>
            <option value="Master">Master</option>
            <option value="Super Admin">Super Admin</option>
            <option value="Admin">Admin</option>
            <option value="Petugas">Petugas</option>
          </>
        );
      case "Super Admin":
        return (
          <>
            <option value="">Pilih Role</option>
            <option value="Super Admin">Super Admin</option>
            <option value="Admin">Admin</option>
            <option value="Petugas">Petugas</option>
          </>
        );
      case "Admin":
        return (
          <>
            <option value="">Pilih Role</option>
            <option value="Admin">Admin</option>
            <option value="Petugas">Petugas</option>
          </>
        );
      default:
        return <option value="">Pilih Role</option>;
    }
  };


  const token = localStorage.getItem('token');
  if (!token) {
    window.location.href = '/';
  }
  return (
    <Layout>
      <div className="flex-grow flex items-center justify-center">
        <div className="max-w-md border p-4">
          <h2 className="text-xl dark:text-white text-center font-semibold mb-4">
            Registrasi
          </h2>
          <form onSubmit={handleSubmit} className="grid gap-4 grid-cols-2">
            {/* First Column */}
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                NRK or NIP
              </label>
              <input
                type="text"
                name="NRKorNIP"
                value={formData.NRKorNIP}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Nama
              </label>
              <input
                type="text"
                name="Nama"
                value={formData.Nama}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Email
              </label>
              <input
                type="text"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Jenis
              </label>
              <select
                name="Jenis"
                value={formData.Jenis}
                onChange={handleChange}
                className="w-full h-10 focus:outline-none  focus:ring-blue-500"
                required
              >
                <option value="">Pilih Jenis</option>
                <option value="KDO">KDO</option>
                <option value="Kapal">Kapal</option>
                <option value="Bus">Bus</option>
                <option value="Petugas">Petugas</option>
              </select>
            </div>

            {/* Second Column */}
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Unit Pelaksana
              </label>
              <select
                name="Satuan"
                value={formData.Satuan}
                onChange={handleChange}
                className="w-full h-10 focus:outline-none  focus:ring-blue-300"
                required
              >
                <option value="">Pilih Unit Pelaksana</option>
                <option value="Sekdishub">Sekdishub</option>
                <option value="Bidlalin">BidLalin</option>
                <option value="BAJ">BAJ</option>
                <option value="BidPelpen">BidPelPen</option>
                <option value="BKA">BKA</option>
                <option value="Dalops">BidDalops</option>
                <option value="Sudinhub Jakarta Pusat">Sudinhub Jakarta Pusat</option>
                <option value="Sudinhub Jakarta Barat">Sudinhub Jakarta Barat</option>
                <option value="Sudinhub Jakarta Timur">Sudinhub Jakarta Timur</option>
                <option value="Sudinhub Jakarta Selatan">Sudinhub Jakarta Selatan</option>
                <option value="Sudinhub Jakarta Utara">Sudinhub Jakarta Utara</option>
                <option value="Sudinhub Kepulauan Seribu">Sudinhub Kepulauan Seribu</option>
                <option value="UPParkir">UP Parkir</option>
                <option value="SJBE">SJBE</option>
                <option value="SPLL">SPLL</option>
                <option value="UPTAJ">UPTAJ</option>
                <option value="UPTTPG">UPTTPG</option>
                <option value="UPAS">UPAS</option>
                <option value="UPPD">UPPD</option>
                <option value="UPAP">UPAP</option>
                <option value="Pusdatinhub">Pusdatinhub</option>
                <option value="PKB_Jagakarsa">PKB Jagakarsa</option>
                <option value="PKB_KedaungAngke">PKB Kedaung Angke</option>
                <option value="PKB_Pulogadung">PKB Pulo Gadung</option>
                <option value="PKB_Cilincing">PKB Cilincing</option>
                <option value="PKB_Ujungmenteng">PKB Ujung Menteng</option>
              </select>
            </div>
            <div>
              <label className="block dark:text-white text-sm font-medium mb-1">
                Role
              </label>
              <select
                name="Role"
                value={formData.Role}
                onChange={handleChange}
                className="w-full h-10 focus:outline-none  focus:ring-blue-500"
                required
              >
                {renderRoleOptions()}
              </select>
            </div>
            <div className="col-span-2 flex justify-center">
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"

              >
                Daftar
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Registration;
