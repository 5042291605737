import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'tailwindcss/tailwind.css';
import L from 'leaflet';
import axios from 'axios';
import pusatIcon from '../assets/images/Icon-SudinPusat.png';
import selatanIcon from '../assets/images/Icon-SudinSelatan.png';
import utaraIcon from '../assets/images/Icon-SudinUtara.png';
import baratIcon from '../assets/images/Icon-SudinBarat.png';
import timurIcon from '../assets/images/Icon-SudinTimur.png';
import userIcon from '../assets/images/icon-petugas.png';
import upasIcon from '../assets/images/icon-BusUPAS.png';
import kdoIcon from '../assets/images/icon-kdo.png';
import kapalIcon from '../assets/images/icon-kapal.png';
import Layout from '../components/Layout';
import derekIdle from '../assets/images/icon-derekIdle.png';
import derekOff from '../assets/images/icon-derekOff.png';
import derekOn from '../assets/images/icon-derekOn.png';
import { FullscreenControl } from "react-leaflet-fullscreen";

const MapWithGeolocation = () => {
  // State untuk menyimpan posisi pengguna
  const [userPosition, setUserPosition] = useState(null);

  // State untuk menyimpan lokasi pengguna lain
  const [userLocations, setUserLocations] = useState([]);
  const [busLocation, setBusLocation] = useState([]);
  const [kdoLocation, setKdoLocation] = useState([]);
  const [kapalLocation, setKapalLocation] = useState([]);
  const [derekIdleLocation, setDerekIdleLocation] = useState([]);
  const [derekOffLocation, setDerekOffLocation] = useState([]);
  const [derekOnLocation, setDerekOnLocation] = useState([]);
  const [selectedJenisFilter, setSelectedJenisFilter] = useState('all');
  const [selectedSatuanFilter, setSelectedSatuanFilter] = useState('all');
  const [selectedDerekStatusFilter, setSelectedDerekStatusFilter] = useState('all');
  const [satuanOptions, setSatuanOptions] = useState([]);

  const Satuan = localStorage.getItem('Satuan');
  console.log(Satuan);
  const userID = localStorage.getItem('IDs');
  // Fungsi untuk mengambil lokasi pengguna saat ini
  const fetchUserLocation = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;
      setUserPosition([latitude, longitude]);
    } catch (error) {
      console.error('Error getting geolocation:', error);
      alert('Error getting geolocation. Mohon izinkan akses lokasi dan coba lagi.');
    }
  };

  // Fungsi untuk mengambil lokasi pengguna lain
  const fetchUserLocations = async () => {
    try {
      const response = await axios.get(`https://jaktraffic.my.id/geoapi/gpslog/recentloc/${userID}`);
      setUserLocations(response.data.data.Petugas);
      setBusLocation(response.data.data.Bus);
      setKdoLocation(response.data.data.KDO);
      setKapalLocation(response.data.data.Kapal);

      console.log(response.data.data);

      const satuanSet = new Set();
      response.data.data.Petugas.forEach((item) => satuanSet.add(item.satuan));
      response.data.data.Bus.forEach((item) => satuanSet.add(item.satuan));
      response.data.data.KDO.forEach((item) => satuanSet.add(item.satuan));
      response.data.data.Kapal.forEach((item) => satuanSet.add(item.satuan));
      setSatuanOptions([...satuanSet]);

    } catch (error) {
      console.error('Error fetching data from the API:', error);
    }
  };

  // Fungsi untuk mengambil lokasi Mobil derek
  const fetchDerekLocations = async () => {
    try {
      const [idle, off, on] = await Promise.all([
        // axios.get(`http://192.168.60.55:4433/apiv1/view-derek-idle`),
        // axios.get(`http://192.168.60.55:4433/apiv1/view-derek-parking`),
        // axios.get(`http://192.168.60.55:4433/apiv1/view-derek-ride`)
        // axios.get(`https://jaktraffic.my.id/apiv1/view-derek-idle`),
        // axios.get(`https://jaktraffic.my.id/apiv1/view-derek-parking`),
        // axios.get(`https://jaktraffic.my.id/apiv1/view-derek-ride`)
      ]);
      setDerekIdleLocation(idle.data.data);
      setDerekOffLocation(off.data.data);
      setDerekOnLocation(on.data.data);
    } catch (error) {
      console.error('Error fetching data from the API:', error);
    }
  };

  useEffect(() => {
    fetchUserLocation();
    fetchUserLocations();
    fetchDerekLocations();
    const intervalId = setInterval(fetchUserLocations, 15 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  //Fungsi untuk memformat waktu dan tanggal
  function formatDateTime(timestamp) {
    if (!timestamp) return '-';

    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString();

    return `${dateString} - ${timeString}`;
  }
  const getIcon = (Satuan) => {
    const icons = {
      'Sudinhub Jakarta Pusat': pusatIcon,
      'Sudinhub Jakarta Selatan': selatanIcon,
      'Sudinhub Jakarta Utara': utaraIcon,
      'Sudinhub Jakarta Timur': timurIcon,
      'Sudinhub Jakarta Barat': baratIcon
    };
    return L.icon({ iconUrl: icons[Satuan] || userIcon, iconSize: [32, 32] });
  };

  if (!localStorage.getItem('token')) {
    window.location.href = '/';
  }
  //Periksa keberadaan token dan alihkan jika tidak ada
  const token = localStorage.getItem('token');
  if (!token) {
    window.location.href = '/';
  }
  const handleJenisFilterChange = (event) => {
    setSelectedJenisFilter(event.target.value);
  };

  const handleSatuanFilterChange = (event) => {
    setSelectedSatuanFilter(event.target.value);
  };
  const handleDerekStatusFilterChange = (event) => {
    setSelectedDerekStatusFilter(event.target.value);
  };
  const filterLocations = (locations, jenis) => {
    return locations.filter((location) => {
      const isJenisMatch = selectedJenisFilter === 'all' || selectedJenisFilter === jenis;
      const satuanField = jenis === 'derek' ? location.group_nm : location.satuan;
      const isSatuanMatch = selectedSatuanFilter === 'all' || satuanField === selectedSatuanFilter;
      const isDerekStatusMatch = selectedJenisFilter !== 'derek' || selectedDerekStatusFilter === 'all' ||
        (selectedDerekStatusFilter === 'idle' && derekIdleLocation.includes(location)) ||
        (selectedDerekStatusFilter === 'on' && derekOnLocation.includes(location)) ||
        (selectedDerekStatusFilter === 'off' && derekOffLocation.includes(location));
      return isJenisMatch && isSatuanMatch && isDerekStatusMatch;
    });
  };
  //Render peta dan informasi terkait
  return (
    <Layout>
      <div>
        <h1 className="text-2xl text-center dark:text-white font-bold mb-4">Lokasi Petugas Lapangan</h1>
        <div className="flex justify-center mb-4">
          <label className="mr-4">
            Pilih Jenis:
            <select value={selectedJenisFilter} onChange={handleJenisFilterChange} className="ml-2 p-2 border rounded">
              <option value="all">Semua</option>
              <option value="petugas">Petugas</option>
              <option value="bus">Bus</option>
              <option value="kdo">KDO</option>
              <option value="kapal">Kapal</option>
              <option value="derek">Derek</option>
            </select>
          </label>
          <label className="mr-4">
            Pilih Satuan:
            <select value={selectedSatuanFilter} onChange={handleSatuanFilterChange} className="ml-2 p-2 border rounded">
              <option value="all">Semua</option>
              {satuanOptions.map((satuan, index) => (
                <option key={index} value={satuan}>{satuan}</option>
              ))}
            </select>
          </label>
          {selectedJenisFilter === 'derek' && (
            <label className="mr-4">
              Pilih Status Derek:
              <select value={selectedDerekStatusFilter} onChange={handleDerekStatusFilterChange} className="ml-2 p-2 border rounded">
                <option value="all">Semua</option>
                <option value="idle">Idle</option>
                <option value="on">On</option>
                <option value="off">Off</option>
              </select>
            </label>
          )}
        </div>
        <div className=" h-screen items-center justify-center">
          <MapContainer center={userPosition || [-6.1754, 106.8272]} zoom={13} style={{ width: '80%', height: '75%', marginLeft: '120px' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <FullscreenControl />
            {filterLocations(userLocations, 'petugas').map((location, index) => (
              <Marker key={index} position={[location.latitude, location.longitude]} icon={getIcon(location.satuan)}>
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">User Location Detail</h2>
                    <p><strong>ID:</strong> {location.nrk_or_nip}</p>
                    <p><strong>Satuan:</strong> {location.satuan}</p>
                    {/* <p><strong>Akurasi:</strong> {location.akurasi}M</p>
                    <p><strong>Latitude:</strong> {location.latitude}</p>
                    <p><strong>Longitude:</strong> {location.longitude}</p> */}
                    <p><strong>Last Update:</strong> {formatDateTime(location.timestamp)}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
            //Bus
            {filterLocations(busLocation, 'bus').map((location, index) => (
              <Marker key={index} position={[location.latitude, location.longitude]} icon={L.icon({ iconUrl: upasIcon, iconSize: [32, 32] })}>
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">User Location Detail</h2>
                    <p><strong>ID:</strong> {location.nrk_or_nip}</p>
                    <p><strong>Satuan:</strong> {location.satuan}</p>
                    {/* <p><strong>Akurasi:</strong> {location.akurasi}M</p>
                    <p><strong>Latitude:</strong> {location.latitude}</p>
                    <p><strong>Longitude:</strong> {location.longitude}</p> */}
                    <p><strong>Last Update:</strong> {formatDateTime(location.timestamp)}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
            //KDO
            {filterLocations(kdoLocation, 'kdo').map((location, index) => (
              <Marker key={index} position={[location.latitude, location.longitude]} icon={L.icon({ iconUrl: kdoIcon, iconSize: [32, 32] })}>
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">KDO Location Detail</h2>
                    <p><strong>ID:</strong> {location.nrk_or_nip}</p>
                    <p><strong>Satuan:</strong> {location.satuan}</p>
                    <p><strong>Akurasi:</strong> {location.akurasi}M</p>
                    <p><strong>Latitude:</strong> {location.latitude}</p>
                    <p><strong>Longitude:</strong> {location.longitude}</p>
                    <p><strong>Last Update:</strong> {formatDateTime(location.timestamp)}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
            //Kapal
            {filterLocations(kapalLocation, 'kapal').map((location, index) => (
              <Marker key={index} position={[location.latitude, location.longitude]} icon={L.icon({ iconUrl: kapalIcon, iconSize: [32, 32] })}>
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Kapal Location Detail</h2>
                    <p><strong>ID:</strong> {location.nrk_or_nip}</p>
                    <p><strong>Satuan:</strong> {location.satuan}</p>
                    <p><strong>Akurasi:</strong> {location.akurasi}M</p>
                    <p><strong>Latitude:</strong> {location.latitude}</p>
                    <p><strong>Longitude:</strong> {location.longitude}</p>
                    <p><strong>Last Update:</strong> {formatDateTime(location.timestamp)}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
            //Derek
            {filterLocations(derekIdleLocation, 'derek').map((location, index) => (
              <Marker key={index} position={[location.lat, location.lon]} icon={L.icon({ iconUrl: derekIdle, iconSize: [32, 32] })}>
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Derek Location Detail</h2>
                    <p><strong>ID:</strong> {location.vehicle_id}</p>
                    <p><strong>Satuan:</strong> {location.group_nm}</p>
                    <p><strong>Nopol:</strong> {location.nopol}</p>
                    <p><strong>Total Odo:</strong> {location.total_km_today}</p>
                    <p><strong>Status:</strong> {location.status}</p>
                    <p><strong>Type:</strong> {location.car_type}</p>
                    <p><strong>Model:</strong> {location.car_model}</p>
                    <p><strong>Last Update:</strong> {formatDateTime(location.lastUpdate)}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
            {filterLocations(derekOffLocation, 'derek').map((location, index) => (
              <Marker key={index} position={[location.lat, location.lon]} icon={L.icon({ iconUrl: derekOff, iconSize: [32, 32] })}>
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Derek Location Detail</h2>
                    <p><strong>ID:</strong> {location.vehicle_id}</p>
                    <p><strong>Satuan:</strong> {location.group_nm}</p>
                    <p><strong>Nopol:</strong> {location.nopol}</p>
                    <p><strong>Total Odo:</strong> {location.total_km_today}</p>
                    <p><strong>Status:</strong> {location.status}</p>
                    <p><strong>Type:</strong> {location.car_type}</p>
                    <p><strong>Model:</strong> {location.car_model}</p>
                    <p><strong>Last Update:</strong> {formatDateTime(location.lastUpdate)}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
            {filterLocations(derekOnLocation, 'derek').map((location, index) => (
              <Marker key={index} position={[location.lat, location.lon]} icon={L.icon({ iconUrl: derekOn, iconSize: [32, 32] })}>
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Derek Location Detail</h2>
                    <p><strong>ID:</strong> {location.vehicle_id}</p>
                    <p><strong>Satuan:</strong> {location.group_nm}</p>
                    <p><strong>Nopol:</strong> {location.nopol}</p>
                    <p><strong>Total Odo:</strong> {location.total_km_today}</p>
                    <p><strong>Status:</strong> {location.status}</p>
                    <p><strong>Type:</strong> {location.car_type}</p>
                    <p><strong>Model:</strong> {location.car_model}</p>
                    <p><strong>Last Update:</strong> {formatDateTime(location.lastUpdate)}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </Layout>
  );
};

export default MapWithGeolocation;
